<template>
  <Fluid class="flex-col items-center px-4">
      <div class="relative fluid-inner overflow-hidden dark:bg-black/10 py-8 md:py-16 rounded-xl" :class="banner.color[color].background">
        <div
          class="absolute inset-0 bg-gradient-to-tr "
          :class="banner.color[color].radial"
        />
        <UContainer
          class="relative"
        >
          <div class="relative z-10">
            <h3 class="text-2xl md:text-4xl mb-4" :class="banner.color[color].heading">
              <slot name="heading" />
            </h3>
            <h4
              v-if="$slots.subheading"
              class="tracking-tight text-gray-700 dark:text-gray-400 text-xl md:text-2xl"
            >
              <slot name="subheading" />
            </h4>
            <div v-if="$slots.buttons" class="flex flex-row flex-wrap gap-4 mt-6 md:mb-10">
              <slot name="buttons" />
            </div>
            <ul
              v-if="$slots.list || !!list?.length"
              class="flex flex-wrap mt-6 gap-4 gap-x-6 md:gap-x-8 transition-colors text-xs md:text-sm"
              :class="banner.color[color].list"
            >
              <slot name="list" />
              <li 
                v-for="item in list"
              >
                <NuxtLink
                  class="group flex items-start"
                  :to="item.to"
                  :class="!!item.to ? 'hover:opacity-80 transition-all' : ''"
                >
                  <UIcon
                    v-if="item.icon"
                    :name="item.icon"
                    class="w-5 h-5 mr-2 relative top-1"
                  />
                  <span
                  >
                    {{ item.label }}
                  </span>
                </NuxtLink>
              </li>
            </ul>

            <slot name="wildcard" />
          </div>
          
        </UContainer>
      </div>
     
    
  </Fluid>
  
</template>

<script lang="ts" setup>



type ListItem = {
  icon?: string
  to?: string;
  label: string
}

const props = withDefaults(defineProps<{
    color?: BaseColor,
    list?: ListItem[]
}>(), { color: 'primary' })

const { banner } = useAppConfig().components

</script>