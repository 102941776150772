<template>
  <NuxtLayout name="site">
    <div class="relative pt-6 md:pt-10">
      <UBreadcrumb :links="crumbs" class="relative z-10 mb-8 md:mb-12" :ui="{wrapper:'max-w-[calc(100vw-2rem)] sm:max-w-[calc(100vw-3rem)]', active: color }" />
      <slot />
      <section class="flex flex-col gap-8 md:gap-16 pt-6 md:pt-12">
        <Banner color="tertiary" :list="[{label: 'voor iedereen een route', icon: 'i-heroicons-user-group-16-solid'}, { label: 'zoek routes op basis van jouw vooropleiding en wensen', icon: 'i-heroicons-fire-16-solid'}, { label: 'vergelijk verschillende route opties', icon: 'i-heroicons-arrows-up-down-16-solid'}]">
          <template #heading>
            Vind jouw route naar het onderwijs
          </template>
          <template #subheading>
            Ontdek alle mogelijke routes naar het onderwijs in de interactive routetool
          </template>
          <template #buttons>
            <UButton to="https://www.onderwijsloket.com/routes/start/" target="_blank" color="tertiary" label="naar routetool" icon="i-heroicons-arrow-up-right-16-solid" trailing />
          </template>
        </Banner>

        <Banner color="primary" :list="[{label: 'een onafhankelijk advies', icon: 'i-heroicons-light-bulb-16-solid'}, { label: 'persoonlijk en op maat', icon: 'i-heroicons-user-circle-16-solid'}, { label: 'op een moment dat jou schikt', icon: 'i-heroicons-calendar-date-range-16-solid'}]">
          <template #heading>
            Liever een persoonlijk advies?
          </template>
          <template #subheading>
            De adviseurs van het Onderwijsloket helpen je verder.
          </template>
          <template #buttons>
            <SavvyCalEmbed />
            <UButton variant="soft" color="white" to="https://www.onderwijsloket.com/plan-een-adviesgesprek/" target="_blank">meer informatie</UButton>
          </template>
        </Banner>
      </section>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { ButtonColor } from '#ui/types'
const props = withDefaults(
  defineProps<{
    crumbs?: {
      label: string,
      icon?: string
      to?: string
    }[]
    crumbColor?: ButtonColor
  }>(), {
    crumbs: () => [],
    crumbColor: 'primary'
  }
)

const color = computed(() => {
  return `text-${props.crumbColor}-500 dark:text-${props.crumbColor}-400`
})
</script>

<style lang="postcss">
.modal .card {
  @apply ring-0 grow h-full flex flex-col;
  .header {
    @apply shrink-0;
  }
  .body {
    @apply grow overflow-scroll;
  }
  .footer {
    @apply py-8 sm:py-4 shrink-0;
  }
}
</style>