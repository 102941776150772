<template>
  <UButton ref="target" :color="color" :label="label" :size="size" icon="i-heroicons-chat-bubble-left-right-20-solid" @click="onOpen" />
</template>

<script lang="ts" setup>
import type { ButtonColor, ButtonSize } from '#ui/types';


const props = withDefaults(
  defineProps<{
    color?: ButtonColor
    label?: string
    size?: ButtonSize
  }>(), {
    color: 'primary',
    label: 'plan een adviesgesprek',
    size: 'md'
  }
)

type SavvyCalScript = {
  SavvyCal: (mode: string, options?: Record<string, any>) => void
}

declare global {
  interface Window extends SavvyCalScript {}
}

const target = ref()
const targetIsVisible = useElementVisibility(target)
const ready = ref(false)
watch(targetIsVisible, (newVal) => {
  if (newVal && !ready.value) load()
}, {
  immediate: true
})


const { load, onLoaded, savvyCal } = useScript('https://embed.savvycal.com/v1/embed.js', {
      trigger: 'manual',
      // tell useScript how to resolve the third-party script
      use() {
        return { savvyCal: window.SavvyCal }
      },
    },
)

const { savvycal } = useAppConfig().settings
const route = useRoute()

const options = computed(() => ({
      link: savvycal.bookingLink,
      metadata: {
          app: 'navigator',
          route: route.path
      },
}))

onLoaded(({ savvyCal }) => {
  savvyCal('init')
  ready.value = true
})

const onOpen = () => {
  const { trackEvent } = useTracking()
  trackEvent('savvycal', {
    event_category: 'engagement',
    event_label: 'savvycal_embed_open'
  })
  savvyCal('open', options.value)
}

</script>

<style>

</style>